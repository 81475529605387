<template>
  <div class="container">
    <div class="card px-5 pb-5">

      <h1 class="mt-5 mb-2">Speedtest</h1>

      <iframe src="//matzradloff.info/speedtest-proxy" style="height: 80vh;"></iframe>
      <!--<iframe src="http://10.1.1.10:9100" style="height: 80vh;"></iframe>-->
    </div>
  </div>
</template>

</template>

<script>

export default {
  data() {
    return {
    }
  },
  methods: {
  }
}

</script>
